import LoadingBlock from "../LoadingBlock";
import styles from '../styles/SchoolFeedBlock.module.scss';
import SchoolFeedList from "../../../schoolfeed/SchoolFeedList";
import {useContext, useEffect, useState} from "react";
import { StoreContext } from "../../../../stores/StoreLoader";
import { observer } from "mobx-react";
import { INewsBlockProps } from "./NewsBlock";
import ViewMoreButton from "../../TerracedGridTheme/ViewMoreButton";
import { useFirstPageSchoolFeedContent } from "../../../schoolfeed/hooks/useFirstPageSchoolFeedContent";
import { ReactiveContentItem } from "../../../../stores/SchoolFeedStore";
import { ITerraceTypes } from "../../TerracedGridTheme/Terrace";
import FeedHeader from "./FeedListHeader";

const SchoolFeedBlock: React.FC<INewsBlockProps> = observer((props: INewsBlockProps) => {
    const { organizationStore } = useContext(StoreContext);


    const {
        data,
        isMoreContent,
        isLoading,
        mutate,
        currentView,
        setCurrentView,
    } = useFirstPageSchoolFeedContent({
        organization_id: organizationStore.currentOrganization.id}
    );

    useEffect(() => {
        props.blockObj.setAttributes({ asyncLoaded: !props.blockObj.asyncLoaded });
    }, [data[currentView]]);

    if (isLoading && data[currentView].length === 0) {
        // also use data[currentView].length because when fetching subsequent pages we don't want to show loading block
        return <LoadingBlock />;
    }

    // Ensure we handleDelete and refetch data
    async function handleDelete(contentItem) {
        // Perform the delete action here
        await mutate();
    }

    const handleCurrentViewChange = (view: string) => {
        setCurrentView(view);
    };

    let currentViewOptions = [{ value: "news", label: "News" }, { value: "events", label: "Events" }]
    if (!data || data?.news?.length===0 || data?.events?.length===0){
        currentViewOptions =  []
    }


    // Ensure we return a valid JSX element or null
    return (
        <div className={styles.container}>
            <FeedHeader
                currentView={currentView}
                handleCurrentViewChange={handleCurrentViewChange}
                currentViewOptions={currentViewOptions}
                content={data[currentView]}
                mutate={mutate}
            />
            {data[currentView].length > 0 ? (
                <SchoolFeedList
                    showMedia={props.blockObj.sizeX > 1}
                    allowCuration={true}
                    content={data[currentView]}
                    handleDelete={handleDelete}
                    totalItemCount={data[currentView].length}
                    getItemAtIndex={idx => {
                        return (data[currentView][idx] as ReactiveContentItem) || null;
                    }}
                />
            ) : <div>No content found</div>}
            {isMoreContent && (
                <div style={{ position: "relative" }}>
                    <ViewMoreButton
                        terraceType={
                            currentView === "news"
                                ? ITerraceTypes.NEWS
                                : ITerraceTypes.EVENTS
                        }
                        organizationId={organizationStore.currentOrganization.id}
                        text={`View More ${currentView === "news" ? "News" : "Events"}`}
                    />
                </div>
            )}
        </div>
    );
});

export default SchoolFeedBlock;
